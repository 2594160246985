/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";                         
import "bootstrap/dist/js/bootstrap.min.js";


import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbreact/dist/css/mdb.css'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

export const onInitialClientRender = () => {
    const noscript = document.createElement("noscript")
    noscript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T22K58B"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `
    document.body.insertBefore(noscript, document.body.firstChild)
  }

// export function shouldUpdateScroll() {
//     window.scrollTo(0, 0)
//     const body = document.getElementsByTagName('body')[0]
//     body.scrollTop = 0
//     return false
//   }

